.psWallet {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 5px;
    .headingContainer {
        display: flex;
        .walletHeading {
            font-size: 24px;
            letter-spacing: -0.36px;
            color: rgba(255, 255, 255, 0.95);
            line-height: 20px;
        }
        .walletTooltipIcon {
            margin-left: 4px;
            width: 18px;
        }
    }
    .walletSubHeading {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.95);
    }
    .walletContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 8px;
        background: #1e2429;
        border-radius: 4px;
        padding: 24px;
        margin-bottom: 50px;
        .balanceLabel {
            font-size: 10px;
            line-height: 12px;
            align-items: center;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.65);
        }
        .balanceAmount {
            font-size: 36px;
            line-height: 32px;
            letter-spacing: 1px;
            color: rgba(255, 255, 255, 0.95);
        }
    }
}

.walletTooltipTextContainer {
    position: absolute;
    width: 250px;
}

.walletError {
    margin-top: 10px;
    font-size: 14px;
    color: #ff4d4f;
}
