@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.button {
    margin-bottom: 27px;
    padding-left: 2px;
    @media (max-width: 60em) {
        margin-bottom: 0;
        padding-left: 0;
        margin-top: 10px;
    }
}

.textContent {
    margin-top: 3px;
    color: $ps-colors-text-icon-low-on-dark;
    font-size: $ps-type-font-size-100;
}

.cstaBorder {
    border-style: solid;
    border-color: $ps-colors-border-low-on-dark;
    padding: 16px;
    border-width: 2px;
    margin-top: 40px;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: noWrap;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 60em) {
        display: block;
    }
}

.titleCss {
    font-size: $ps-type-font-size-200;
    font-weight: $ps-type-font-weight-500;
}
