.centerSpinner {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}

.addPaymentHeader {
    margin-top: 25px;
    margin-bottom: 10px;
}
