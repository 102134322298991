.centerVertical {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .loaderBox {
        justify-content: center;
        align-items: center;
        width: 50%;

        .loaderText {
            color: #ffffff;
            font-weight: 600;
            font-size: 34px;
            line-height: 44px;
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;
        }
    }
}

.loaderProgress {
    overflow: auto;
}
