@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.errorPageLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.errorMessage {
    text-align: center;
    font-size: 28px;
    font-weight: $ps-type-font-weight-500;
}

.mainCTAButton {
    width: 242px;
    height: 48px;
    font-size: $ps-type-font-size-400;
    margin-bottom: 19px;
    margin-top: 40px;
}

.backToPlanMessage {
    text-decoration: underline;
}

.subErrorMessage {
    font-size: $ps-type-font-size-400;
}

.contactSupportContent {
    border-top: 1px solid $ps-colors-border-low-on-dark;
    border-bottom: 1px solid $ps-colors-border-low-on-dark;
    width: 330px;
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
    padding: 35px 5px;
}

.supportInfo {
    height: 45px;
    margin-left: 23px;
    display: flex;
    flex-direction: column;
}

.email {
    font-size: $ps-type-font-size-400;
    font-weight: $ps-type-font-weight-500;
}

.availability {
    color: $ps-colors-text-icon-low-on-dark;
    font-size: $ps-type-font-size-200;
}

.logo {
    margin: 15px 23px;
}

.refresh {
    text-decoration: underline;
    cursor: pointer;
    background-color: $ps-colors-background-dark-1;
    color: $ps-colors-text-icon-high-on-dark;
    border: none;
    font-size: 18px;
    line-height: 21px;
    padding: 0;
}
