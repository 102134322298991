@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.paypalMargin {
    margin-left: 15px;
}
.errorLabel {
    display: flex;
    align-items: center;
    font-weight: $ps-type-font-weight-strong;
    color: $ps-colors-status-error;
    font-size: 11px;
}

.visaInfo {
    display: flex;
    align-items: center;
}

.paypalEmail {
    font-size: 11px;
    margin-top: 5px;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.paymentCard {
    margin-top: 3px;
}
.creditCardMargin {
    margin-left: 12px;
}

.cardDisplayMargin {
    margin-left: 12px;
    flex-direction: column;
    display: flex;
    font-size: 14px;
}
.paymentButtonHeader {
    font-weight: 700;
    font-size: 14px;
}
.paymentMethod {
    height: 60px;
    width: calc(50% - 5px);
    @media (max-width: 769px) {
        width: 100%;
    }
    padding: 5px 10px;
    background-color: $ps-colors-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $ps-colors-text-icon-high-on-light;
    border-color: #b3b3b3;
    border-radius: 2px;
    position: relative;
    transition: 0.2s background-color ease;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 10px;
    text-align: left;
    cursor: pointer;
}

.expiredPaymentMethod {
    width: calc(50% - 5px);
    @media (max-width: 769px) {
        width: 100%;
    }
    padding: 5px 10px;
    background-color: $ps-colors-white;
    display: flex;
    flex-direction: column;
    color: $ps-colors-text-icon-high-on-light;
    border-color: #b3b3b3;
    border-radius: 2px;
    position: relative;
    transition: 0.2s background-color ease;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 10px;
    text-align: left;
}

.dot {
    width: 16px;
    height: 16px;
    left: calc(50% - 16px / 2);
    top: calc(50% - 16px / 2);
    background: inherit;
    border: 2px solid rgba(0, 0, 0, 0.55);
    border-radius: 25px;
    margin-left: auto;
}

.activeDot {
    background: $ps-colors-blue-5;
    border: none;
}

.checkmark {
    z-index: 9;
    height: 5px;
    width: 9px;
    top: 27px;
    right: 13px;
    border-width: 2px;
    border-style: none none solid solid;
    border-color: white;
    position: absolute;
    transform: scale(1) rotate(-45deg) translateX(1px) translateY(-1px);
}

.achMargin {
    margin-left: 15px;
}

.bankName,
.bankAccountType,
.bankAccountNumber {
    font-size: 11px;
    margin-top: 5px;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
}
