.react-datepicker__input-container input {
    position: relative;
    height: 40px;
    min-width: 192px;
    width: 100%;
    border-radius: 2px;
    background: #ffffff;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #555555;
    padding: 8px 16px;
    border: 1px solid #aaaaaa;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 999;
}

.react-datepicker {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    width: 99%;
}

.datepicker-title {
    margin-bottom: -15px;
}
