@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.icon {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    border-radius: 100%;
    text-align: center;
    line-height: 30px;
    background: $ps-colors-background-utility-25;
    color: $ps-colors-text-icon-low-on-dark;
    transition: 0.3s background-color;
    position: relative;
}

.iconActive {
    color: white;
}

.value {
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(1, 0, 0.85, 1.7);
}

.completed {
    transform: scale(0);
    transition: transform 0.3s ease;
}
.checkmark {
    height: 7px;
    width: 13px;
    top: 12px;
    left: 9px;
    border-width: 3px;
    border-style: none none solid solid;
    border-color: #999;
    position: absolute;
    transform: scale(0) rotate(-45deg) translateX(1px) translateY(-1px);
    transition: transform 0.3s ease;
}

.activeCheckmark {
    border-color: white;
}

.completeCheckmark {
    transform: scale(1) rotate(-45deg) translateX(1px) translateY(-1px);
    transition: transform 0.3s cubic-bezier(1, 0, 0.85, 1.7);
}
