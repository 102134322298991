@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.rowBase {
    background: $ps-colors-background-dark-2;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 95px;
}

.rowBaseForIcon {
    justify-content: space-between;
}
.section {
    display: flex;
    flex-direction: row;
}

.rowHeader {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    padding-left: 9px;
    color: $ps-colors-text-icon-low-on-dark;
}

.activeRowHeader {
    color: $ps-colors-text-icon-high-on-dark;
}

.completedRowHeaderHover {
    :hover {
        text-decoration: underline;
        color: $ps-colors-white;
        cursor: pointer;
    }
}
