.priceLockContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    // margin-top: 20px;
    .priceLockText {
        font-size: 11px;
        line-height: 18px;
    }
    .headingContainer {
        font-size: 14px;
        font-weight: 600;
    }
    .walletIconWrapper {
        background-color: #0074ab;
        padding: 5px;
        border-radius: 50%;
        // margin: 0 8px;
        // margin-left: 3px;
        margin-right: 8px;
        .walletIcon {
            margin-top: 5px;
            // margin-right: 3px;
            width: 26px;
            height: auto;
        }
    }
}
