.deleteModal {
    background: #222222;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.36px;
    color: #f2f2f2;
    border-radius: 4px;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.modalButtons {
    display: grid;
    grid-template-columns: repeat(3, 0.9fr);
    justify-content: center;
}
