.header {
    border-bottom: 1px solid #cccccc;
    padding: 0 0 16px 0;
}

.content {
    padding-left: 118px;
    margin-top: 38px;
    @media (max-width: 60em) {
        padding-left: 0;
        margin-top: 19px;
    }
}
