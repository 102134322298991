.review {
    background: #181c20;
    border-radius: 2px;
    padding: 0 20px;
    .content {
        height: 200px;
        color: rgba(255, 255, 255, 0.95);
        line-height: 16px;
        top: calc(50% - 43px / 2 + 8.5px);
        font-size: 12px;
        .margin {
            margin-bottom: 15px;
        }
    }
}

@media (max-width: 760px) {
    .review {
        .content {
            height: 250px;
        }
    }
}
