@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.button {
    margin-bottom: 25px;
}

.labelStyles {
    > label > div:nth-child(1) {
        font-size: 14px;
    }
}

.bannerButton {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    color: $ps-colors-text-icon-high-on-light;
    &:hover {
        color: $ps-colors-text-icon-low-on-light;
    }
}
