@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.integrationsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px;
}

.integrationsBanner {
    display: block;
    span {
        font-weight: bold;
    }
}

.bannerContainer {
    margin-bottom: 30px;
}

.boxItemsContainer {
    background-color: #222222;
    padding: 30px 32px 12px 32px;
}

.lineItem {
    margin-top: 25px;
}

.integrationsHeader {
    color: $ps-colors-text-icon-high-on-dark;
    font-size: $ps-type-font-size-400;
    font-weight: $ps-type-font-weight-500;
    line-height: 24px;
    margin-bottom: 10px;
}
.integrationsHeaderContainer {
    margin: 0 0 50px 50px;
    max-width: 600px;
}

.iconContainer {
    margin: 50px 20px 0 0;
}

.sectionContainer {
    display: flex;
}
.boxItemValue {
    width: 400px;
}

.boxItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
