.edit-promo {
    min-height: 700px;
    .promo-actions {
        margin-top: 45px;
        margin-bottom: 45px;
        display: flex;
        button {
            min-width: 100px;
            margin-right: 15px;
        }
    }
    .edit-section {
        margin-top: 50px;
        max-width: 330px;
    }
}
