@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.productsLayout {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 24px;
    @media (max-width: 760px) {
        grid-row-gap: 12px;
    }
    .addOn {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        @media (max-width: 760px) {
            grid-template-columns: auto;
            grid-row-gap: 12px;
            grid-column-gap: 12px;
        }
    }
    .upsellBox {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        @media (max-width: 760px) {
            grid-template-columns: auto;
        }
    }
    .productBox {
        background: #1e2429;
        border-radius: 4px;
        padding: 21px 29px;
        display: grid;
        grid-template-areas:
            'ProductHeader ProductLinks'
            'ProductName   ProductName'
            'LicenseCount  LicenseCount'
            'ProductFooter ProductFooter';
        @media (max-width: 760px) {
            grid-template-areas: 'ProductHeader ProductHeader' 'ProductName ProductName' 'LicenseCount LicenseCount' 'ProductFooter ProductFooter' 'ProductLinks ProductLinks';
        }
    }
    .addOnHeader {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.65);
        grid-area: ProductHeader;
    }
    .plan {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.65);
        grid-area: ProductHeader;
    }
    .product {
        margin-top: 8px;
        font-size: 36px;
        line-height: 40px;
        letter-spacing: -0.54px;
        color: rgba(255, 255, 255, 0.95);
    }
    .addOnProduct {
        margin-top: 8px;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.36px;
        color: rgba(255, 255, 255, 0.95);
    }
    .addOnPilot {
        text-transform: uppercase;
        margin-top: 8px;
        font-size: 12px;
        line-height: 20px;
        background-color: $ps-colors-background-utility-25;
        padding-right: 5px;
        padding-left: 5px;
        border-radius: 2px;
    }
    .addonPilotRow {
        display: grid;
        grid-template-columns: min-content max-content;
        align-items: center;
        grid-column-gap: 5px;
    }
    .count {
        margin-top: 7px;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        grid-area: LicenseCount;
    }
    .addOnCount {
        margin-top: 7px;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        grid-area: LicenseCount;
    }

    .future-available-date {
        font-style: italic;
        margin-top: 10px;
        grid-area: ProductFooter;
    }
    .product-links {
        grid-area: ProductLinks;
        text-align: right;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        @media (max-width: 760px) {
            text-align: left;
            margin-top: 11px;
        }
    }
    .recommendationText {
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.65);
        margin-bottom: 10px;
        margin-top: 15px;
    }
    .trialDetailsBox {
        font-size: 12px;
        display: inline;
        margin-left: 35px;
        background: #8a99a840;
        border-radius: 2px;
        padding: 5.5px 4px;
        vertical-align: top;
    }
    .trialDetailsText {
        display: inline;
        padding: 10px 5px;
        letter-spacing: 0.01em;
    }
    .upsell {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        background: #181c20;
        border-radius: 4px;
        padding: 21px 29px;
        .upsellHeading {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.36px;
            color: rgba(255, 255, 255, 0.95);
        }
        .upsellDescription {
            font-size: 12px;
            line-height: 16px;
            color: rgba(255, 255, 255, 0.65);
        }
        .upsellFooter {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 10px 0;
            .upsellText {
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
            }
            .upsellButton {
                font-size: 14px;
                line-height: 16px;
            }
            .upsellNewButton {
                background-color: #037ab3;
                color: #ffffff;
                border: unset;
            }
            .upsellQuantityText {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
    .toolTip {
        display: inline;
        position: absolute;
        margin-top: -10px;
        padding: 0px 5px;
    }
}
.psds-tooltip--appearance-basic {
    display: grid !important;
    max-width: fit-content !important;
}
.psds-button--appearance-flat.psds-theme--dark {
    color: var(--ps-colors-primary-action-text-light-theme) !important;
    background: #fff !important;
    box-shadow: none !important;
    font-size: 14px;
    margin-left: 5px;
    text-decoration: underline;
    padding: 0px;
    height: auto;
}
.addOnInfo {
    position: absolute;
    margin-top: 8px;
    width: 18px;
    height: 18px;
}

.link-disabled {
    pointer-events: none !important;
    color: gray !important;
}
