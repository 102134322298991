@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';
.rows {
    > * {
        padding-left: 30px;
        margin-bottom: 20px;
        background: $ps-colors-background-dark-2;
        padding-right: 30px;
        padding-bottom: 10px;
    }
}

.header {
    padding-bottom: 20px;
}

.page {
    padding-top: 20px;
}

.expand {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
    @media (min-width: 769px) {
        display: flex;
        justify-content: center;
        max-width: 1330px;
    }
}

.aside {
    width: 100%;
    @media (min-width: 769px) {
        width: calc(45% - 10px);
    }
}

.mainDisplay {
    width: 100%;
    margin-right: 20px;
    @media (min-width: 769px) {
        width: calc(55% - 10px);
    }
}

.accountSwitcher {
    width: 100%;
    margin-bottom: 16px;
}

.spacer {
    height: 64px;
}

.expandContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.backToPlan {
    width: 100%;
    max-width: 1330px;
    margin: auto;
    padding: 10px;
}

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #222222;
    color: white;
    width: 90%;
    max-width: 385.51px;
    height: 312px;
    padding: 15px 24px 24px 24px;
    border-radius: 4px;
    gap: 18px;
    @media (min-width: 769px) and (max-width: 1024px) {
        width: 80%;
        max-width: 500px;
    }
    @media (min-width: 1025px) {
        width: 385.51px;
        height: 312px;
    }
}

.modal hr {
    width: 100%;
    max-width: 337.51px;
    border-color: rgba(255, 255, 255, 0.15);
}

.modalHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(243, 243, 248, 1);
    padding-bottom: 20px;
    font-weight: 600;
}

.modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #222222;
    color: white;
    padding: 20px;
}

.modalBody img {
    height: 50px;
    width: 50px;
    margin: 20px 0;
}

.modalBody b {
    color: rgba(243, 243, 248, 1);
    width: 318px;
    height: 54px;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-wrap: break-word;
    gap: 4px;
}

.pageBlur {
    filter: blur(5px);
    pointer-events: none;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

:global {
    //The knowledgeOwl widget gets loaded automatically by adobe analytics
    #koWidget > div > button {
        display: none;
    }
}
