@import '~@pluralsight/ps-design-system-core/dist/index.module';

.promo-row {
    cursor: pointer;
    &:hover {
        background-color: $ps-colors-background-dark-3;
    }
}

.promo-col-id {
    max-width: 40px;
}

.promo-col-cta {
    max-width: 150px;
}
