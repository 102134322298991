@import 'node_modules/@pluralsight/ps-design-system-core/dist/index.module';

.promotion-configuration-container {
    margin-top: 45px;
    margin-bottom: 45px;
}

.no-data {
    margin-top: 25px;
    font-size: 20px;
}

.create-new {
    margin-bottom: 25px;
}
