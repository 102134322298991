@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.limitedLibraryItem {
    font-size: $ps-type-font-size-200;
    color: $ps-colors-text-icon-high-on-dark;
    line-height: 24px;
    margin: 12px 0;
    text-align: left;
}

.freemiumProduct {
    font-weight: 600;
}

.noSubCopy {
    font-size: $ps-type-font-size-200;
    color: $ps-colors-text-icon-high-on-dark;
    line-height: 24px;
    margin: 12px 0;
}

.bottomMargin {
    margin-bottom: 10px;
}

.paymentHistory {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid;
    border-color: $ps-colors-border-low-on-dark;
}
.section {
    margin: 24px 0;
}

.buttonRow {
    margin-top: 16px;
    margin-right: 12px;
}
.ctaRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.contentInfo {
    font-size: $ps-type-font-size-200;
    color: $ps-colors-text-icon-high-on-dark;
    line-height: 24px;
    margin: 12px 0;
    text-align: left;
    width: 50%;
}
.labels {
    font-size: $ps-type-font-size-200;
    line-height: 24px;
    color: $ps-colors-text-icon-low-on-dark;
    margin: 12px 0;
    width: 50%;
}
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}

.cta {
    min-width: 110px;
}
