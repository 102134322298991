@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.pageLayout {
    margin: 114px auto;

    .messageLayout {
        max-width: 550px;
        margin: auto;
    }

    .header {
        margin: 34px auto;
    }

    .inputWrapper {
        margin-top: 26px;
    }

    .topicsField {
        width: 475px !important;
    }

    .subheader {
        margin: 20px auto;
        font-size: $ps-type-font-size-400;
    }

    .subheaderBold {
        font-weight: 700;
    }

    .form {
        margin: 20px auto 24px;
    }

    .textStyles {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    .linkWrapper {
        margin-top: 50px;
        text-align: center;
    }
}
