@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.highOnDarkText {
    font-weight: 600;
    letter-spacing: 0.005em;
    font-size: 16px;
    line-height: 20px;
    color: $ps-colors-text-icon-high-on-dark;
    @media (max-width: 925px) {
        font-size: 11px;
    }
}

.upperCase {
    text-transform: uppercase;
}

.lowOnDarkText {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: $ps-colors-text-icon-low-on-dark;
    letter-spacing: 0.08em;
}

.row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    > div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 120px;
        height: 24px;
    }
}
