@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.navTabs {
    color: $ps-colors-text-icon-low-on-dark;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
}

.TabLayout {
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: auto auto 800px;
    padding-top: 20px;
    border-bottom: 0.5px solid #555555;
    @media (max-width: 600px) {
        grid-template-columns: 1fr 1fr 80px;
    }
}

.navLayout {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 40px 0 0 180px;
    @media (max-width: 600px) {
        padding: 40px 0 0 20px;
    }
}

.activeTab {
    padding-bottom: 10px;
    border-bottom: 4px solid $ps-colors-blue-6;
    text-decoration: none;
    color: $ps-colors-text-icon-high-on-dark;
    width: fit-content;
}
.bannerMessage {
    display: flex;
    align-items: center;
    background-color: #377a41;
}
