.centerVerticalError {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

#ps-account-switcher > div {
    position: static;
}
