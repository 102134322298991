.transaction-conditions {
    div {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.65);
        line-height: 16px;
        margin-top: 15px;
    }
    @media (max-width: 760px) {
        div {
            margin-bottom: 10px;
        }
    }
}
