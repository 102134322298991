@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.buttonSection {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    & button {
        margin: 0 8px;
    }
}

.container {
    width: 100%;
    max-width: 600px;
    font-weight: $ps-type-font-weight-500;
    & div {
        margin: 15px 0;
    }
}

.title {
    font-size: 28px;
}

.errorPageLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15%;
}
