@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.userName {
    font-size: $ps-type-font-size-600;
    margin: 16px 0;
    width: 640px;
}

.label {
    font-size: $ps-type-font-size-200;
    line-height: 24px;
    color: $ps-colors-text-icon-low-on-dark;
    margin: 12px 0;
    width: 10%;
}

.contentMain {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
    align-content: stretch;
    font-weight: 400;
}

.contentInfo {
    font-size: $ps-type-font-size-200;
    color: $ps-colors-text-icon-low-on-dark;
    line-height: 24px;
    margin: 12px 0;
    text-align: left;
    width: 60%;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}
