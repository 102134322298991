@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.appContent {
    max-width: 830px;
    width: 100%;
    padding: 48px;
    margin: 0 auto;
    @media (max-width: 60em) {
        width: 100%;
        padding: 19px 24px;
    }
    font-weight: 400;
}

.titleText {
    font-size: $ps-type-font-size-400;
    margin: 16px 0;
    font-weight: $ps-type-font-weight-500;
}

.labels {
    font-size: $ps-type-font-size-200;
    line-height: 24px;
    color: $ps-colors-text-icon-low-on-dark;
    margin: 12px 0;
    width: 50%;
}

.contentInfo {
    font-size: $ps-type-font-size-200;
    color: $ps-colors-text-icon-high-on-dark;
    line-height: 24px;
    margin: 12px 0;
    text-align: left;
    width: 50%;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
}

.button {
    margin-top: 16px;
}

.buttonRow {
    margin-top: 16px;
    margin-right: 12px;
}

.ctaRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.sectionWrap {
    margin-bottom: 50px;
}
