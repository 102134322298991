@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.contactSupportContent {
    border-top: 1px solid $ps-colors-border-low-on-dark;
    border-bottom: 1px solid $ps-colors-border-low-on-dark;
    width: 330px;
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
    padding: 35px 5px;
}

.supportInfo {
    height: 45px;
    margin-left: 23px;
    display: flex;
    flex-direction: column;
}

.availability {
    color: $ps-colors-text-icon-low-on-dark;
    font-size: $ps-type-font-size-200;
}
