@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.planIdDisplay {
    color: $ps-colors-text-icon-high-on-dark;
}
.singleAccountDisplay {
    color: $ps-colors-text-icon-low-on-dark;
    font-size: 18px;
    line-height: 24px;
    height: 40px;
}
