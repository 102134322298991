.loadingDecoratorStyles {
    margin: auto;
}

.centerVertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-weight: 700;
    font-size: 16px;
}
