@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.navBar {
    width: 100%;
    background-color: $ps-colors-background-dark-3;
    color: $ps-colors-text-icon-low-on-dark;
    letter-spacing: -0.21px;
    a {
        color: $ps-colors-text-icon-low-on-dark;
        text-decoration: none;
        padding-top: 10px;
        margin: 0 17px;
        height: calc(100% - 12px);
        display: inline-block;
    }
    a:hover {
        color: $ps-colors-text-icon-high-on-dark;
    }
    .navLink {
        color: $ps-colors-text-icon-high-on-dark;
        border-bottom: 6px solid $ps-colors-blue-6;
    }
    @media (min-width: 700px) {
        height: 60px;
    }
}

.accountLayout {
    display: none;
    font-size: $ps-type-font-size-600;
    padding: 9px 50px 0 30px;
    @media (min-width: 1024px) {
        display: inline-block;
    }
}
