@import '~@pluralsight/ps-design-system-core/dist/index.module';

.navTabs {
    color: $ps-colors-text-icon-low-on-dark;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 4px solid transparent;
    display: inline-block;
    margin-right: 30px;
}

.tabLayout {
    width: 100%;
    padding-top: 20px;
    border-bottom: 0.5px solid #555555;
}

.activeTab {
    border-bottom-color: $ps-colors-blue-6;
    text-decoration: none;
    color: $ps-colors-text-icon-high-on-dark;
}

.contentArea {
    margin: 40px 50px 0 100px;
    @media (max-width: 600px) {
        margin: 40px 10px 0 20px;
    }
}
