.transactionErrorContentLayout {
    display: flex;
    max-width: 900px;
    margin: 0 auto;
}

.ps-logo {
    margin-top: 15px;
    margin-left: 23px;
}
