.existingPaymentHR {
    border: 1px solid #363636;
    margin-top: 10px;
    margin-bottom: 20px;
}

.paymentRow {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 8px;
}
