@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.expandFooter {
    border-radius: 3px;
    background: $ps-colors-background-dark-2;
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 0;
    width: 100%;
    box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.4);
    padding: 20px;
}
.horizontalRuleColor {
    border-color: $ps-colors-border-low-on-dark;
}

.firstHrMargin {
    margin-bottom: 18px;
}

.secondHrMargin {
    margin-bottom: 22px;
}

.expandFooterHrMargin {
    margin-top: 16px;
    margin-bottom: 18px;
}

.loadingDecoratorMargin {
    margin-left: auto;
}

.row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    > div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 120px;
        height: 24px;
    }
}

.amountPickerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    > div > div:nth-child(1) {
        align-items: baseline;
        margin-bottom: 15px;
    }
    > div:nth-child(3) {
        width: 160px;
    }
    @media (max-width: 925px) {
        flex-wrap: wrap;
        > div > div:nth-child(1) {
            margin-bottom: 0px;
        }
        > div:nth-child(1) {
            order: 1;
            width: 50%;
        }
        > div:nth-child(2) {
            order: 3;
            width: 100%;
            margin-top: 20px;
        }
        > div:nth-child(3) {
            order: 4;
            margin-top: 20px;
        }
        > div:nth-child(4) {
            order: 2;
            width: 50%;
        }
    }
}

.textAlignRight {
    text-align: right;
}

.footer-table {
    width: 100%;
}

.textAlignCenter {
    text-align: center;
}
.orderSummaryBaseWithDiscount {
    background: $ps-colors-background-dark-2;
    border: none;
    height: 360px;
    width: 100%;
    padding: 20px 30px;
}
.orderSummaryBase {
    background: $ps-colors-background-dark-2;
    border: none;
    // height: 330px;
    width: 100%;
    padding: 20px 30px;
}

.orderSummaryHeader {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 25px;
}

.totalRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 925px) {
        font-size: 12px;
    }
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 33px;
    letter-spacing: 0.005em;
}

.upperCase {
    text-transform: uppercase;
}
.loader {
    display: flex;
    justify-content: center;
}
.productName {
    height: 34px;
    line-height: 17px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 925px) {
        font-size: 11px;
    }
}

.quantity {
    height: 34px;
    line-height: 17px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 925px) {
        font-size: 11px;
    }
}
.lowOnDarkText {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: $ps-colors-text-icon-low-on-dark;
    letter-spacing: 0.08em;
}

.highOnDarkText {
    font-weight: 600;
    letter-spacing: 0.005em;
    font-size: 16px;
    line-height: 20px;
    color: $ps-colors-text-icon-high-on-dark;
    @media (max-width: 925px) {
        font-size: 11px;
    }
}

.topRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h3 {
        display: flex;
        flex-direction: row;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
}

.amountPickerContainer {
    height: 34px;
}

.daggers {
    display: inline-block;
    vertical-align: top;
    font-size: 9px;
    line-height: 9px;
}

.chevron {
    display: block;
    content: ' ';
    background: transparent;
    border-width: 2px;
    border-style: solid none none solid;
    height: 8px;
    width: 8px;
    border-color: #eaf5ff;
    transform: rotate(-135deg);
}

.openCaret {
    margin: 0 15px;
    transition: transform 0.3s;
    transform: rotate(225deg) translateY(-2px) scale(0.8);
}

.closedCaret {
    margin: 0 15px;
    transition: transform 0.3s;
    transform: rotate(45deg) translateY(8px) scale(0.8);
}
