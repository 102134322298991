.radioButton {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
}

.appContent {
    max-width: 830px;
    width: 100%;
    padding: 48px;
    font-weight: 400;
    margin: 0 auto;
    @media (max-width: 60em) {
        width: 100%;
        padding: 19px 24px;
    }
}
