.transactionMainPage {
    display: block;
    margin: 30px;
    .mainContent {
        margin: 8px 0 0 12%;
        @media (max-width: 760px) {
            margin: 0;
        }
    }
}

.rowLayout {
    display: flex;
    flex-direction: row;
    .cell1 {
        width: 690px;
        margin-right: 20px;
    }
    .cell2 {
        width: 450px;
    }
    h2 {
        padding-bottom: 20px;
    }
}
$maxWidth: '760px';
@media (max-width: $maxWidth) {
    .rowLayout {
        display: flex;
        flex-direction: column;
        h2 {
            padding-bottom: 5px;
            margin: 8px 0;
        }
        .cell1 {
            width: 100%;
            margin-right: 0;
        }
        .cell2 {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .mainPage {
        min-height: 100%;
        position: relative;
        margin: 20px;
        max-width: 100%;
        height: 100%;
        .mainContent {
            margin: 0;
            height: 100%;
        }
    }
    .header {
        flex-direction: row;
        justify-content: space-between;
        div {
            margin: 3px 0 0 0;
        }
    }
}

.backLink {
    margin-bottom: 8px;
}
