@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.accountSwitcher {
    height: 40px;
    color: $ps-colors-text-icon-low-on-dark;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.dropdown {
    > div {
        width: 100%;
    }
    display: block;
    width: 100%;
}

.accountHeader {
    margin-right: 10px;
}

.accountName {
    color: rgba(255, 255, 255, 0.95);
}
