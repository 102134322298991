@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.termsAndConditions {
    font-size: 12px;
    line-height: 18px;
    > div {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
.lowOnDark {
    > div {
        margin-bottom: 20px;
        margin-top: 20px;
        color: $ps-colors-text-icon-low-on-dark;
    }
}
