@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.layoutDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.contentText {
    font-size: $ps-type-font-size-100;
    color: $ps-colors-text-icon-high-on-dark;
}

.content {
    font-size: 10px;
    @media (max-width: 60em) {
        padding-left: 0;
    }
}

.image {
    height: 100px;
    width: 100px;
    padding: 20px 30px 0 0;
}

.ctaBlock {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    color: $ps-colors-text-icon-high-on-dark;
    text-decoration: underline;
}

.buttonText {
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    font-size: $ps-type-font-size-200;
}

.ctaButton {
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    color: $ps-colors-text-icon-high-on-dark;
    margin-bottom: 12px;
    font-size: $ps-type-font-size-100;
}
