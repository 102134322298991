.orderSummary {
    background: rgb(24, 28, 32);
    padding: 30px 22px;
    .header {
        display: block;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .line {
        border: 1px solid rgba(255, 255, 255, 0.15);
    }
    .summary {
        table {
            width: 100%;
            th {
                text-align: left;
                color: rgba(255, 255, 255, 0.65);
                font-size: 12px;
            }
            td {
                letter-spacing: 0.000725926px;
                color: rgba(255, 255, 255, 0.95);
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    .subTotal {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        .row {
            display: flex;
            justify-content: space-between;
            width: 95%;
        }
        .spacer {
            margin-bottom: 14px;
        }
        .label {
            font-weight: 500;
            line-height: 14px;
            color: rgba(255, 255, 255, 0.65);
            width: 100%;
            font-size: 12px;
            text-align: right;
        }
        .values {
            width: 80%;
            color: rgba(255, 255, 255, 0.95);
            font-size: 14px;
            text-align: right;
            line-height: 17px;
            letter-spacing: 0.000725926px;
        }
    }
    .total {
        display: flex;
        justify-content: space-between;
        .amountLabel {
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0.000593939px;
            color: rgba(255, 255, 255, 0.95);
        }
        .amount {
            text-align: right;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0.000593939px;
            color: rgba(255, 255, 255, 0.95);
        }
    }
    hr {
        border: 1px solid rgba(255, 255, 255, 0.15);
        margin-top: 22px;
        margin-bottom: 18px;
    }
}

@media (max-width: 769px) {
    .mobileHeader {
        display: inline;
        font-size: 14px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.95);
    }
    .hide-line {
        display: none;
    }
    .amount {
        float: right;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.000461953px;
        color: rgba(255, 255, 255, 0.95);
    }
    .arrow-up {
        margin-left: 10px;
        box-sizing: border-box;
        position: relative;
        display: inline;
        transform: scale(var(--ggs, 1));
        width: 22px;
        height: 22px;
        border: 2px solid transparent;
        border-radius: 100px;

        &::after {
            content: '';
            display: inline;
            box-sizing: border-box;
            position: absolute;
            width: 10px;
            height: 10px;
            border-top: 2px solid;
            border-right: 2px solid;
            transform: rotate(-45deg);
            left: 4px;
            bottom: 2px;
        }
    }
    .arrow-down {
        margin-left: 10px;
        box-sizing: border-box;
        position: relative;
        display: inline;
        transform: scale(var(--ggs, 1));
        width: 22px;
        height: 22px;
        border: 2px solid transparent;
        border-radius: 100px;

        &::after {
            content: '';
            display: inline;
            box-sizing: border-box;
            position: absolute;
            width: 10px;
            height: 10px;
            border-bottom: 2px solid;
            border-right: 2px solid;
            transform: rotate(45deg);
            left: 4px;
            top: 2px;
        }
    }

    .orderSummary {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

.dagger {
    display: inline-block;
    vertical-align: top;
    font-size: 9px;
    line-height: 9px;
}

.bulkDiscount {
    background-color: #770ef7;
    color: #ffffff;
    padding: 3px 7px;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
}
