@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.addPaymentButton {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    color: $ps-colors-text-icon-high-on-dark;
    &:hover {
        color: $ps-colors-text-icon-low-on-dark;
    }
}

.noSavedPaymentsBanner {
    background: #127fc9;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
