@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.accountName {
    color: $ps-colors-text-icon-high-on-dark;
}

.singleAccount {
    color: $ps-colors-text-icon-high-on-dark;
    font-size: 18px;
    line-height: 24px;
    height: 40px;
    @media (max-width: 1075px) {
        font-size: 16px;
    }
}
