.psds-dialog {
    background: #0d0f12;
}
.psds-dialog__overlay {
    background-color: rgba(0, 0, 0, 0.8);
}

.mainPage {
    display: block;
    margin: 30px;
    .mainContent {
        margin: 8px 0 0 12%;
    }
}
.plan_head1 {
    padding: 15px 15px 0px 15px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #fff;

    @media (max-width: 1023px) {
        width: auto;
        margin: auto;
    }
}
.plan_head2 {
    padding: 15px 15px 0px 15px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #fff;

    @media (max-width: 1023px) {
        width: auto;
        margin: auto;
        font-size: 30px;
    }
}

.plan_head3 {
    padding: 15px 15px 0px 15px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 32px;
    letter-spacing: -0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    font-weight: bold;
    color: #fff;

    @media (max-width: 1023px) {
        width: auto;
        margin: auto;
        font-size: 30px;
    }
}
.plan_title {
    width: 834px;
    padding: 0vw 0vw 0vw 3vw;

    @media (max-width: 1023px) {
        width: auto;
        padding: 0px;
    }
}
.pricing_flex-container {
    display: flex;
    color: #fff;
    justify-content: center;
    margin: 40px auto 0;
    text-align: center;

    @media (max-width: 1023px) {
        display: block;
    }
}
.pricing_section {
    background: linear-gradient(180deg, #181818 0%, #222222 100%);
    box-shadow: 0 20px 25px -2px rgba(0, 0, 0, 0.4);
    padding: 0px 0px 50px 0px;
    width: 300px;
    @media (max-width: 1023px) {
        margin: auto;
        width: auto;
        border-bottom: 1px solid #363638;
    }
}
.pricing_wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    @media (max-width: 1023px) {
        margin-top: 10px;
    }
}
.pricing_title {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: 1023px) {
        margin: auto;
        font-size: 24px;
        font-weight: bold;
    }
}
.pricing_curr {
    font-size: 20px;
    font-weight: bold;
    margin-top: -5px;

    @media (max-width: 1023px) {
        font-size: 15px;
        margin-top: -3px;
    }
}
.pricing_value {
    font-size: 40px;
    font-weight: bold;
    margin-left: 5px;

    @media (max-width: 1023px) {
        font-size: 20px;
    }
}
.pricing_year {
    font-size: 15px;
    margin-top: 15px;

    @media (max-width: 1023px) {
        font-size: 15px;
    }
}
.pricing_subs {
    margin: 40px 0px;
    padding: 0px 30px;
    font-size: 17px;
    font-weight: 400;
    color: #fff;

    @media (max-width: 1023px) {
        font-size: 16px;
        margin: auto;
        margin-top: 10px;
    }
}
.pricing_subs1 {
    margin: 40px 0px;
    padding: 0px 30px;
    font-size: 17px;
    font-weight: 400;
    color: #ec008c;

    @media (max-width: 1023px) {
        margin: auto;
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
    }
}
.btn_view {
    display: inline-grid;

    @media (max-width: 1023px) {
        margin: auto;
    }
}
.btn_view > button {
    width: 136px;
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 12px;
    background: linear-gradient(to right, #f05a28, #ec008c, #f15b2a) !important;
    border-radius: 2px;
    transition: 0.6s ease-in-out;
    text-transform: uppercase;
    height: auto !important;
    font-weight: bold !important;
    box-shadow: none !important;
    background-size: 300% 100% !important;

    &:hover {
        background-position: -50% 0 !important;
    }

    @media (max-width: 1023px) {
        font-size: 13px !important;
        font-weight: 500;
    }
}
.anchor_tag {
    margin: 5px;
    color: #fff;
}

.previous_plan {
    background: #e80a89;
}
.previous_plan_text {
    height: 25px;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: bold;
}
