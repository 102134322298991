.review-transaction {
    background: #181c20;
    border-radius: 2px;
    padding: 0 20px 10px 20px;
    .review-content {
        color: rgba(255, 255, 255, 0.95);
        line-height: 16px;
        top: calc(50% - 43px / 2 + 8.5px);
        font-size: 12px;
        .review-margin {
            margin-bottom: 15px;
        }
        .add-on-message {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
        }
    }
}
