.shimmer {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 25%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.1) 75%
    );
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer-animation 1.5s infinite;
}

@keyframes shimmer-animation {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
