.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 75vh;
}

.mainPage {
    display: block;
    margin: 30px;
    .mainContent {
        margin: 8px 0 0 12%;
    }
}
