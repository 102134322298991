.adminContainer {
    width: 100%;
    display: grid;
    grid-row-gap: 40px;

    .Flow-border {
        border-left: 3px solid #2974bb;
        padding-left: 20px;
        @media (max-width: 760px) {
            border-left: none;
            padding-left: 0;
        }
    }
    .ProServ-border {
        border-left: 3px solid #00a894;
        padding-left: 20px;
        @media (max-width: 760px) {
            border-left: none;
            padding-left: 0;
        }
    }

    .lineItemContainer {
        > div {
            margin-bottom: 20px;
        }
    }

    .productTypeLabel {
        margin: 0 0 15px 0;
    }

    .proServeResize {
        height: 20px;
        width: 144px;
    }
    .acgResize {
        margin-left: -14px;
        margin-top: -13px;
        margin-bottom: -19px;
    }
}

.Skills-border {
    border-left: 3px solid #ed2662;
    padding-left: 20px;
    @media (max-width: 760px) {
        border-left: none;
        padding-left: 0;
    }
}
.ACG-border {
    border-left: 3px solid #f68921;
    padding-left: 20px;
    @media (max-width: 760px) {
        border-left: none;
        padding-left: 0;
    }
}
