.tooltipTextContainer {
    position: absolute;
    width: 110px;
    text-align: center;
}

.infoIconOrderSummary {
    height: 10px;
    width: 15px;
    margin-left: 5px;
}

.infoIconThankYou {
    height: 10px;
    width: 10px;
    margin-left: 5px;
}
