.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    height: 100vh;
    width: 100%;
    .heading {
        height: 28px;
        left: 0;
        width: 1440px;
        text-align: center;
        top: 115px;
        font-size: 36px;
        line-height: 18px;
        @media (max-width: 760px) {
            height: 81px;
            left: 4px;
            width: 375px;
            text-align: center;
            top: 50px;
            font-size: 36px;
            line-height: 40px;
            span {
                display: block;
            }
        }
    }
    .subHeading {
        height: 24px;
        left: 0;
        top: 158px;
        width: 1440px;
        text-align: center;
        font-size: 24px;
        line-height: 24px;
        margin-top: 15px;
        @media (max-width: 760px) {
            height: 80px;
            left: 41px;
            top: 150px;
            width: 293px;
            text-align: center;
            font-size: 18px;
        }
    }
    .buttonRow {
        display: flex;
        div {
            margin-top: 30px;
            padding: 12px;
            height: 40px;
        }
    }
    hr {
        width: 682px;
        height: 0;
        left: 379px;
        top: 298px;
        border: 1px solid #363636;
        margin-top: 46px;
        @media (max-width: 760px) {
            width: 260px;
            left: 58px;
            top: 324px;
        }
    }
    .notificationText {
        font-size: 18px;
        line-height: 24px;
        margin-top: 38px;
        text-align: center;
    }
    .paymentText {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.000593939px;
        margin-top: 35px;
        span {
            display: block;
        }
    }
    @media (max-width: 760px) {
        margin: 25px 0;
    }
}

.receipt {
    margin-top: 30px;
    width: 350px;
    background: rgb(24, 28, 32);
    padding: 30px 22px;
    .header {
        display: block;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .line {
        border: 1px solid #363636;
    }
    .summary {
        table {
            width: 100%;
            th {
                text-align: left;
                color: rgba(255, 255, 255, 0.65);
                font-size: 12px;
            }
            td {
                letter-spacing: 0.000725926px;
                color: rgba(255, 255, 255, 0.95);
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    .subTotal {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .label {
            margin-right: 32px;
            display: flex;
            color: rgba(255, 255, 255, 0.65);
            font-size: 12px;
            text-align: right;
            flex-direction: column;
        }
        .values {
            display: flex;
            color: rgba(255, 255, 255, 0.95);
            font-size: 12px;
            text-align: right;
            flex-direction: column;
            letter-spacing: 0.000725926px;
            margin-right: 10px;
        }
    }
    .total {
        display: flex;
        justify-content: space-between;
        .amountLabel {
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0.000593939px;
            color: rgba(255, 255, 255, 0.95);
        }
        .amount {
            margin-right: 10px;
            text-align: right;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0.000593939px;
            color: rgba(255, 255, 255, 0.95);
            @media (max-width: 769px) {
                float: right;
                margin-right: 20px;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.000461953px;
                color: rgba(255, 255, 255, 0.95);
            }
        }
    }
    hr {
        border: 1px solid rgba(255, 255, 255, 0.15);
        margin-top: 22px;
        margin-bottom: 18px;
        width: 100%;
    }
}
