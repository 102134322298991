@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.defaultTag {
    padding: 4px 16px 4px 16px;
    color: white;
    background: $ps-colors-background-utility-25;
    position: relative;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 15px;
}
