.paymentBox {
    background: #181c20;
    border-radius: 2px;
    padding: 0 20px;
    .content {
        // height: 350px;
        color: rgba(255, 255, 255, 0.95);
        line-height: 16px;
        top: calc(50% - 43px / 2 + 8.5px);
        font-size: 14px;
        display: flex;
        flex-direction: column;
        hr {
            border: 1px solid rgba(255, 255, 255, 0.15);
            margin: 15px 0;
        }
    }
}

@media (max-width: 760px) {
    .paymentBox {
        .content {
            height: 100%;
        }
    }
}
