.accountDetails {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    @media (max-width: 760px) {
        grid-row-gap: 12px;
    }
    .termDetails {
        background: #1e2429;
        border-radius: 4px;
        width: 100%;
        padding: 21px 29px;
        display: grid;
        grid-template-areas: 'Text Link' 'TermData TermData' 'FooterData FooterData';
        @media (max-width: 760px) {
            grid-template-areas: 'Text Text' 'TermData TermData' 'FooterData FooterData' 'Link Link';
        }
        .text {
            color: rgba(255, 255, 255, 0.65);
            grid-area: Text;
        }
        .link {
            text-align: right;
            font-weight: 600;
            font-size: 14px;
            grid-area: Link;
            @media (max-width: 760px) {
                text-align: left;
                margin-top: 11px;
            }
        }
        .termData {
            margin-top: 8px;
            font-size: 36px;
            line-height: 40px;
            letter-spacing: -0.54px;
            color: rgba(255, 255, 255, 0.95);
            grid-area: TermData;
        }
        .subscribeText {
            margin-top: 9px;
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;
            grid-area: TermData;
        }
        .subscribeBtn {
            background-color: transparent;
            color: #2ea0d6;
            font-size: 14px;
            font-weight: 600;
            border: none;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                color: #0074ab;
            }
        }
        .subscribeBtnDisabled {
            color: gray;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            border: none;
        }
        .resellerInfo {
            margin-top: 8px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.08em;
            color: rgba(255, 255, 255, 0.95);
            text-transform: uppercase;
            grid-area: FooterData;
        }
    }
}

.termsLayout {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 760px) {
        display: grid;
        grid-row-gap: 12px;
        grid-template-columns: auto;
    }
}
