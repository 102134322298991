@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.integrationsContentContainer {
    max-width: 600px;
}

.integrationHeader {
    border-top: 2px solid rgba(170, 170, 170, 0.4);
    padding: 50px 0 16px 0;
    @media (max-width: 60em) {
        margin-bottom: 19px;
    }
}

.content {
    font-size: $ps-type-font-size-200;
    color: #cccccc;
}
