@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.upgradeContainer {
    width: 85%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: 30px;
}
.header {
    font-weight: 600;
    padding-bottom: 20px;
}
.rows {
    > * {
        padding-left: 30px;
        padding-bottom: 10px;
        padding-right: 30px;
        margin-bottom: 20px;
        background: $ps-colors-background-dark-2;
    }
}
.pageContainer {
    padding: 20px;
}
.backToPlanMessage {
    width: 100%;
    margin: auto;
    font-size: 12px;
}

.upgrade {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.accountSwitcher {
    margin-bottom: 16px;
}

.mainLayout {
    width: 100%;
    margin-right: 20px;
    @media (min-width: 769px) {
        width: calc(60% - 10px);
    }
}

.aside {
    width: 100%;
    @media (min-width: 769px) {
        width: calc(40% - 10px);
    }
}
