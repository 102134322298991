.psContact {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    .contactHeading {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.36px;
        color: rgba(255, 255, 255, 0.95);
    }
    .contactContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 8px;
        background: #1e2429;
        border-radius: 4px;
        padding: 24px;
        .nameLabel {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            align-items: center;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.65);
        }
        .contactName {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.01em;
            color: rgba(255, 255, 255, 0.95);
        }
        .contactInfo {
            display: flex;
            @media (max-width: 760px) {
                flex-direction: column;
            }
            .contactCell {
                display: flex;
                @media (max-width: 760px) {
                    margin-top: 10px;
                }
                .contactSeparator {
                    border: 1px solid rgba(255, 255, 255, 0.15);
                    margin: 0 12px;
                    @media (max-width: 760px) {
                        display: none;
                    }
                }
            }
        }
    }
}
