.walletTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    .walletText {
        font-size: 12px;
        .walletAmountLoading {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .walletIconWrapper {
        background-color: #fe1777;
        padding: 5px;
        border-radius: 50%;
        margin: 0 5px;
        .walletIcon {
            margin-top: 5px;
            width: 80%;
            height: auto;
        }
    }
}
