@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.messageLayout {
    width: 450px;
    margin: auto;
    text-align: center;
    > div {
        margin: 34px auto;
    }
}

.textStyles {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.b2bStaticPageLayout {
    background-color: $ps-colors-background-dark-3;
    height: calc(100vh - 123px);
    padding-top: 15%;
    display: block;
}
