@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.amountPicker {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.carrotButton {
    width: 17px;
    height: 17px;
    background: $ps-colors-background-utility-25;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    position: relative;
    z-index: 10;
    margin-left: 4px;
    margin-right: 4px;

    :focus {
        outline: none;

        :after {
            content: '  ';
            background: transparent;
            border: 3px solid #137bc2;
            height: 25px;
            width: 25px;
            position: absolute;
            top: -4;
            border-radius: 50%;
            z-index: 9;
        }
    }

    :active {
        top: 1px;
    }
}

.input {
    text-align: center;
    background-color: $ps-colors-white;
    color: $ps-colors-text-icon-high-on-light;
    width: 48px;
    height: 25px;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    :focus {
        outline: none;
    }

    @media (min-width: 769px) {
        height: 30px;
        font-size: 14px;
    }
}
