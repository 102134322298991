@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
    align-content: stretch;
    font-weight: 400;
    margin-top: -42px;
}

.content {
    width: 640px;
}

.row {
    margin-bottom: 50px;
}

.extend {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    font-size: $ps-type-font-size-100;
}

.colorBlue {
    color: $ps-colors-blue-6;
}

.appContent {
    max-width: 830px;
    width: 100%;
    padding: 48px;
    margin: 0 auto;
    @media (max-width: 60em) {
        width: 100%;
        padding: 19px 24px;
    }
    font-weight: 400;
}
