@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.plans {
    background-color: #fff;
    border-radius: 2px;
    padding: 10px 10px 10px 15px;
    display: flex;
    height: 50px;
    flex-direction: row-reverse;
    justify-content: space-between;
    cursor: pointer;
    width: 100% !important;
}

.radio > div > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio > div > div > div > svg {
    margin: 1px;
}

.check_icon {
    position: absolute;
    right: 15px;
    background: #fff;
    border-radius: 2rem;
    margin: 2px;
}
.plans > div {
    color: rgb(12, 3, 3) !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.025em;
    font-feature-settings: 'tnum' on, 'lnum' on;
}

.btn_disable > div > div {
    display: none;
}
.plans > div > div > div {
    background: #fff;
    border: 2px solid #b3b3b3;
    width: 18px;
    height: 18px;
    margin-bottom: 4px;
}

.planBox {
    background: #1e2429;
    border-radius: 2px;
    width: 40%;
    padding: 10px 30px 35px 30px;
}

.link {
    padding: 8px 12px;
}

.cont_button {
    text-align: end;
    padding: 20px 0px 20px 20px;
}
.cont_button > button {
    font-weight: 600;
}
.plan_head_text {
    text-align: center;
    padding: 30px 25px 30px 25px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #ffffff;
}
