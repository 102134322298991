.pop-up-modal {
    .pop-up-heading {
        width: 430px;
        height: 33px;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.36px;
        margin: 10px 0 20px 0;
    }
    .pop-up-text {
        top: -0.4px;
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 40px;
    }
}
.xIcon {
    path {
        fill: rgba(255, 255, 255, 0.95);
    }
}
