.layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    height: 100vh;
    width: 100%;
    @media (max-width: 60em) {
        margin: 25px 0;
    }
}

.heading {
    @media (max-width: 60em) {
        height: 50px;
        left: 4px;
        width: 375px;
        text-align: center;
        top: 50px;
        font-size: 36px;
        line-height: 40px;
        span {
            display: block;
        }
    }
}

.subHeading {
    height: 24px;
    left: 0;
    top: 158px;
    width: 1440px;
    text-align: center;
    line-height: 24px;
    font-size: 18px;
    @media (max-width: 60em) {
        height: 80px;
        left: 41px;
        top: 150px;
        width: 293px;
        text-align: center;
    }
}

.ruling {
    width: 682px;
    height: 0;
    left: 379px;
    top: 298px;
    border: 1px solid #363636;
    margin-top: 46px;
    @media (max-width: 60em) {
        width: 260px;
        left: 58px;
        top: 324px;
    }
}

.button {
    margin-top: 30px;
    padding: 12px;
    height: 40px;
    a {
        text-decoration: none;
    }
}

.buttonRow {
    display: flex;
}

.note {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.000593939px;
    margin-top: 22px;
    text-align: center;
}

.orderSum {
    background-color: #191d21;
    padding: 20px;
    width: 430px;
    .ruling1 {
        border: 1px solid #363636;
        margin: 30px 0 15px 0;
    }
    .row {
        display: grid;
        grid-template-columns: 1.75fr 3fr auto;
        justify-content: space-between;
        align-content: start;
        margin-bottom: 10px;
        .second {
            margin-left: -110px;
            text-align: center;
        }
        div {
            font-size: 12px;
            line-height: 14px;
            color: #aaaaaa;
            height: 10.42px;
            margin: 5px 0;
        }
        p {
            font-size: 10px;
            line-height: 17px;
            color: #ffffff;
            height: 19px;
            letter-spacing: 0.000725926px;
        }
    }
    .midrow {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: end;
        grid-column-gap: 35px;
        grid-row-gap: 10px;
        text-align: right;
        div {
            font-size: 10px;
            line-height: 17px;
            letter-spacing: 0.000725926px;
        }
        .first {
            font-size: 12px;
            line-height: 14px;
            color: #aaaaaa;
        }
        .sym {
            display: inline-block;
            vertical-align: top;
            font-size: 9px;
            line-height: 9px;
        }
    }
    .ruling2 {
        border: 1px solid #363636;
        margin: 15px 0 20px 0;
    }
    .total {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        letter-spacing: 0.000593939px;
        font-size: 14px;
        line-height: 22px;
    }
}

.orderSummaryHeading {
    margin-bottom: 10px;
}

.emailDisplay {
    margin-top: 20px;
    margin-bottom: 27px;
    font-size: 16px;
    text-align: center;
}

.priceLockMessage {
    width: 430px;
    margin-bottom: 10px;
}
