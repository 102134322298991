@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';
.container {
    font-family: 'PS TT Commons Roman';
}
.content-heading {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 5%;
}
.renew-text-headind {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-top: 22px;
    margin-bottom: 14px;
    letter-spacing: -1%;
    color: #ffffff !important;
}
.para-text {
    width: 90%;
    font-size: 12px;
    margin-left: 20px;
    text-align: center;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 15px;
    letter-spacing: 2.5%;
    color: #ffffff !important;
}
h5 {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 4px;
    letter-spacing: 2.5%;
    color: #ffffff !important;
}
.Border-container {
    border: 1px solid #babbbd;
    border-radius: 4px;
    max-width: 418px;
    margin-bottom: 10px;
}
.plan-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 10px;
    margin: 5px;
}
.seat-adjustment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 10px;
    margin: 5px;
}
.button-Section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}
.quantity-button button {
    border: none;
    background: none;
    font-size: 20px;
    font-weight: 700;
}
.quantity-button {
    width: 107px;
    height: 31px;
    padding: 5px;
    margin-right: -5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #babbbd;
    border-radius: 4px;
}
.close-button {
    background: none;
    border: none;
    outline: none;
    color: rgba(187, 189, 190, 1);
    font-size: 40px;
    font-weight: 400;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 1px;
}
.active-button {
    cursor: pointer;
    width: '30px';
    height: '30px';
    color: #ffffff;
}
.dis-button {
    color: #7a7a7a;
    width: '30px';
    height: '30px';
}
.error-box {
    background-color: #fdc932;
    font-size: 12px;
    font-weight: 500;
    height: 39px;
    text-align: center;
    padding: 10px;
}
.current-plan-text {
    font-size: 13px;
    color: #ffffff;
    font-weight: 600;
}
.sku-text {
    font-size: 12px;
    margin-top: -5px;
    color: #ffffff;
    font-weight: 400;
}
.seats-text {
    font-size: 14px;
    padding-right: 25px;
    font-weight: 400;
    color: #ffffff;
}
.horizontal-line {
    height: 1px;
    border-width: 0;
    background-color: #babbbd;
    color: #babbbd;
}
.addtional-seats-text {
    font-size: 12px;
    color: #ffffff;
    font-weight: 600;
}
.go-back-button {
    margin-left: -12px;
    height: 40px;
    width: 89px;
    color: rgba(255, 255, 255, 0.7) !important;
    border-radius: 4px;
    background: #1e2428;
}
.continue-button {
    height: 40px;
    width: 173px;
    border-radius: 4px;
}
.center-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.info-container {
    font-family: 'PS TT Commons Roman';
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    height: 100%;
    gap: 10%;
}
.error-body {
    margin: 0px 20px;
}
.special-offer {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 4px;
    letter-spacing: 2.5%;
    color: #ffffff !important;
    padding: 5px 10px;
    background-color: #770ef7;
    border-radius: 5px;
    display: inline;
}
.special-text {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}
.upgrade-plan-details {
    width: 100%;
    text-align: center;
}
.upgrade-everything-text {
    @extend .para-text;
    font-size: 16px;
    margin-bottom: 5px;
}
.copy-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 5px;
    letter-spacing: 2.5%;
    color: #ffffff !important;
}
.what-included-text {
    @extend .copy-text;
    margin-top: 20px;
}
.renew-button {
    @extend .button-Section;
    display: inline-block;
}
.upgrade-button {
    @extend .continue-button;
    width: 250px;
}
.current-plan-button {
    @extend .continue-button;
    width: 250px;
    margin-top: -10px;
    background-color: #b3bbbe36 !important;
}
.current-plan-button:hover {
    background-color: #b3bbbe18 !important;
}
.plan-include-details {
    @extend .copy-text;
    line-height: 19px;
    font-weight: 200;
    margin-left: auto;
    margin-right: auto;
    width: 52%;
}
.plan-include-details div {
    text-align: left;
}
.green-tick-mark {
    color: green;
    margin-bottom: -5px;
    margin-right: 5px;
}
.psds-dialog__content {
    overflow-y: hidden;
}
