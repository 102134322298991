@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.sectionWrap {
    margin-bottom: 50px;
}

.invoiceTable {
    overflow: auto;
    margin-right: 0;
}

.tableHeaderLine {
    border: 1px solid #363636;
}

.invoiceColumnHeader {
    font-size: 10px;
    line-height: 16px;
    color: #fff;
}

.invoiceCell {
    font-size: 11px;
    line-height: 16px;
    color: #fff;
    height: 20px;
}

.payInvoiceButton {
    background: $ps-colors-primary-action-background;
    color: $ps-colors-text-icon-high-on-dark;
    font-size: 12px;
    border: 1px solid $ps-colors-primary-action-background;
    border-radius: 2px;
    width: 122px;
    height: 32px;
    white-space: nowrap;
}

.noPaymentHistoryBanner {
    background: $ps-colors-status-info;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loadMoreButton {
    margin-top: 20px;
}

.scrollableTable {
    min-width: 586px;
}
