@import '~@pluralsight/ps-design-system-core/dist/index.module.scss';

.orderSummaryContainer {
    background: $ps-colors-background-dark-2;
    padding: 20px 30px;
    border: none;
    width: 100%;
    @media (max-width: 1075px) {
        height: 330px;
    }
}

.orderSummaryBody {
    color: $ps-colors-text-icon-low-on-dark;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.subtotalContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
}

.subtotalBody {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalContainer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    @media (max-width: 1075px) {
        font-size: 11px;
    }
}

.header {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
    @media (max-width: 1075px) {
        font-size: 16px;
    }
}

.subHeader {
    margin-bottom: 10px;
    @media (max-width: 925px) {
        margin-top: 10px;
    }
}

.daggers {
    display: inline-block;
    vertical-align: top;
    font-size: 9px;
    line-height: 9px;
}

.chevron {
    display: block;
    content: '';
    background: transparent;
    border-width: 2px;
    border-style: solid none none solid;
    height: 8px;
    width: 8px;
    border-color: #eaf5ff;
    transform: rotate(-135deg);
}

.upperCase {
    text-transform: uppercase;
}

.planDetail {
    font-size: 14px;
    font-weight: 600;
    @media (max-width: 1075px) {
        font-size: 11px;
    }

    span {
        font-weight: 300 !important;
    }
}

.subtotalHeader {
    color: $ps-colors-text-icon-low-on-dark;
    font-size: 10px;
}

.horizontalRule {
    border-color: $ps-colors-border-low-on-dark;
    margin-bottom: 10px;
}

.topRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h3 {
        display: flex;
        flex-direction: row;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
}

.caret {
    margin: 0 15px;
    transition: transform 0.3s;
    transform: rotate(45deg) translateY(8px) scale(0.8);
}

.openCaret {
    transform: rotate(225deg) translateY(-2px) scale(0.8);
}

.walletMessageDiv {
    margin-top: 20px;
}

.bulkDiscountBody {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.bulkDiscountRow {
    font-size: 14px;
    font-weight: 600;
    @media (max-width: 1075px) {
        font-size: 11px;
    }
}

.bulkDiscountSpan {
    background-color: #770ef7;
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    padding: 3px 7px;
    margin-left: 5px;
    margin-right: 45px;
    border-radius: 5px;
}
